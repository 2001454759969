<template>
  <TTView>
    <VRow>
      <VCol>
        <h1>Buttons</h1>
      </VCol>
    </VRow>

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.primary"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.secondary"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.secondary.danger"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.danger"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.ghost"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.unactive"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.icon.left"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.icon.right"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.icon"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.icon.round"
    />

    <TTExample
      class="mt-7"
      file="components/TTTooltip/examples/TTTooltip.bottom"
    />

    <TTExample
      class="mt-7"
      file="components/TTTooltip/examples/TTTooltip.left"
    />

    <TTExample
      class="mt-7"
      file="components/TTTooltip/examples/TTTooltip.right"
    />

    <TTExample
      class="mt-7"
      file="components/TTBtn/examples/TTBtn.icon.pill"
    />

    <div class="mt-12" />
  </TTView>
</template>

<script>
export default {
  name: 'Btn',
};
</script>
